<template>
    <div class="wrap" v-loading="loading">
        <div class="left">
            <div>
				
                <div class="pdf_upload_box"  v-if=" is_owner">
                  
					<a-upload
					    name="file"
					    :multiple="false"
					    action="/addons/chatgpt/xfmlb/basefile_upload"
					    :headers="headers"
					    @change="handleChange2"
						:data="filedata"
					    accept=".pdf,.docx,.txt"
					    class="pdf_upload_btn"
					    :beforeUpload="beforeUpload"
						:showUploadList="true"
					>
                        <a-button> <a-icon type="upload" /> 添加文档 </a-button>
                    </a-upload>
                </div>
               <div class="basename">
                   {{basename}}
               </div>
                <div class="pdf_search">
                    <a-input-search  v-model="keyword"  placeholder="搜索文档" style="width: 100%;opacity: 0.5;background: #fff; border-radius: 4px" />
                </div>
            </div>
            <div class="history" v-if="history_data.length !=  0">
                <div class="history_box"
                     v-for="(value,index) in history_data"
                     v-if="is_search(value.pdf_name,keyword)"
                     @click="upload_xfyun(value.id,index)"
                     :key="index"
                     v-on:mouseenter="handleMouseEnter(index)"
                     v-on:mouseleave="handleMouseLeave(index)"
                >
                    <div class="hb_left">
                        <img src="../assets/imgs/ocr.svg">
                    </div>
                    <div class="hb_right">
                        <div v-if="!value.is_edit" class="limited-text">{{value.pdf_name}}</div>
                        <div class="pdf_name" v-if="value.is_edit">
							<a-input v-model="value.pdf_name" />
						</div>
                        <div>{{value.created_at}}</div>
                    </div>
                    <div class="icon" v-if="value.show && is_owner">
                        <div>
                            <a-icon v-if="!value.is_edit" style="font-size: 18px" @click.stop="edit_icon(index)" type="edit" />
                            <a-icon v-if="value.is_edit" style="font-size: 18px" @click.stop="edit_pdf_name(index,value.id)" type="check" />
                        </div>
                        <div>
                            <a-icon @click.stop="showModal(value.id)" style="font-size: 18px" type="delete" />
                        </div>
                    </div>
                    <a-modal
                        title="删除文档"
                        :visible="visible"
						ok-text="确定"
						cancel-text="取消"
                        :confirm-loading="confirmLoading"
                        @ok="handleOk(del_id)"
                        @cancel="handleCancel"
                    >
                        <p>{{ ModalText }}</p>
                    </a-modal>
                </div>
            </div>
        </div>
        <div class="right">

            <div class="qa_mian" :class="{ hide: fileIds == '' }">
                <div class="word" v-if="this.fileType == 2">
                    <div v-if="this.fileType == 2" ref="childRef" id="wordView" class="childRef"></div>
                </div>
                <div class="txt" v-if="this.fileType == 3">
                    <div><pre style="white-space: pre-line;font-family: inherit;">{{txtContent}}</pre></div>
                </div>
                <div class="pdf" v-if="this.fileType == 1">
                    <div v-if="this.fileType == 1" class="onlineHelp cg-box">
                        <div class="tools">
                            <div class="page">第 {{pageNum}} /{{pageTotalNum}}页 </div>
                            <el-input v-model.number="goPageNum" style="width: 70px;margin-right: 8px"></el-input>
                            <el-button type="success" @click.stop="goPage"> 前往</el-button>
                            <el-button type="primary" @click.stop="prePage"> 上一页</el-button>
                            <el-button type="primary" @click.stop="nextPage"> 下一页</el-button>
                        </div>
                        <div v-if="this.fileType == 1" class="pdf-box">
                            <pdf  ref="pdf"
                                 :src="url"
                                 :page="pageNum"
                                 @progress="loadedRatio = $event"
                                 @page-loaded="pageLoaded($event)"
                                 @num-pages="pageTotalNum=$event"
                                 @error="pdfError($event)"
                                 @link-clicked="page = $event">
                            </pdf>
                        </div>
                    </div>
                </div>
                <div class="qa_content">
                    <div class="qa_top">
                        <div>对话窗口</div>
                        <a-button :loading="iconLoading" @click="sum_up">文档总结<a-icon v-if="sumup_open" type="caret-up" /><a-icon v-if="!sumup_open" type="caret-down" /></a-button>
                    </div>
                    <div class="sumup" v-if="sumup_open">
                        <div class="sumup_title">
                            <div><img src="../assets/icons/dialog-avatar.svg"> 以下是该文档的总结/概要信息:</div>
                            <div class="copy"><img src="../assets/icons/copy-gray.svg">复制</div>
                        </div>
                        <div class="sumup_text">
                            <div>{{sumup_text}}</div>
                        </div>
                    </div>
                    <div class="qa_box">
<!--                        <p>Status: {{ connectionStatus }}</p>-->
                        <div v-for="(item,index) in dialogue" class="user dialogue_box" :class="item.role">
							<div v-if="item.role == 'user'" class="avatar"><img :src="userInfo.avatar"></div>
							<div v-if="item.role != 'user'"  class="avatar"><img src="../assets/imgs/dialog.png"></div>
							<div class="content_box">
							    <div v-if="item.role == 'user'">{{ item.time }}<v-md-editor v-model="item.content" mode="preview"></v-md-editor></div>
							    <div v-if="item.role == 'ai' && item.status == 1">{{ item.time }}<v-md-editor v-model="answer" mode="preview"></v-md-editor></div>
							    <div v-if="item.role == 'ai' && item.status == 2">{{ item.time }}<v-md-editor v-model="item.content" mode="preview"></v-md-editor>
								</div>
								<div class="copy1" style="margin-top:10px;font-size:12px;margin-right:220px;float: right;"  @click="messageCopy(item.content)" >
								<img  style="width:16px;" src="../assets/icons/copy-gray.svg">  复制 </div>
							    <div style="font-size: 12px;margin-top:10px"></div>
							</div>
                        </div>
                    </div>
                    <div class="qa_bottom">
                        <div class="question" style="margin-top: 10px;">
                            <img src="../assets/icons/send.svg" @click="send()" >
                            <a-input v-model="question" style="width: 85%;" placeholder="请输入问题" @keyup.enter="send()" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>
<script setup>
import {mapActions, mapGetters} from "vuex";
import ClipboardJS from "clipboard";
import pdf from 'vue-pdf'
import mammoth from 'mammoth';
import { defaultOptions, renderAsync } from "docx-preview";
export default {
    components: {pdf},
        data() {
        return {
            socket: null,
            connectionStatus: 'Disconnected',
            headers: {
                authorization: 'authorization-text',
                Token:'',
            },
            num:0,
            question:'',
            answer:'',
            eg:'',
            currentTime: '',
            wss:'',
            dialogue:[],
            fileIds:'',
            desc:'',
            loading:false,
            btn_loading:false,
            history_data:[],
            is_fetch:false,
            is_edit:false,
            ModalText: '删除后不可找回，确认删除当前会话？',
            visible: false,
            confirmLoading: false,
            pdf_id:0,
            sumup_status:1,
            sumup_text:'',
            sumup_open:false,
            iconLoading:false,
            url:'',
            pageNum: 1,
            pageTotalNum: 1,
            // 加载进度
            loadedRatio: 0,
            curPageNum: 0,
            goPageNum: 1,
            arr:[1],
            keyword:'',
            wordText: '',
            fileType:'',
            txtContent:'',
            previewImages:[{ src: '', alt: 'Text File' }],
            filedata:'',
            basename:'',
            is_owner:false,
			del_id:''
            // pdf_show:false

        };
    },
    watch: {

        keyword(newVal, oldVal) {

            console.log(`输入值已从 ${oldVal} 变为 ${newVal}`);

            // 在这里你可以执行任何需要在输入值变化时进行的操作

        }

    },
    created() {
        this.getUserPdf();
        this.getwss();
        this.getbasedata();

    },
    mounted() {
        const clipboard = new ClipboardJS(".copy", { text: () => this.sumup_text });
        clipboard.on("success", (e) => {
            this.copiedText = this.clipboardText;
            e.clearSelection();
            this.$message.success(
                '复制成功',
                1,
            );
        });


    },
    computed:{
        ...mapGetters("user", ["token", "userInfo"]),
        id() {
            // this.base_id = this.$route.params.id;
            return this.$route.params.id;
        }
    },
    methods: {
        ...mapActions("user", ["getUserInfo"]),
		async messageCopy(text) {
						try {
							await navigator.clipboard.writeText(text)
							this.$message.success("已复制到剪切板")
						} catch (err) {
							this.$message.error("复制失败")
						}
					},
        getwss(){
            this.$http("role.getbasewss",{id:this.id}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    // console.log(res.data);

                    this.fileIds = res.data.data;
                    this.wss = res.data.url;
                    this.eg = res.data.eg;

                    this.connectToServer();


                    // console.log(this.history_data.length)
                }

            })
        },
        getbasedata(){
            this.$http("role.getbasedata",{id:this.id}).then(res => {
                if(res.code == 1){
                    this.dialogue = JSON.parse(res.data.dialogue);
                    this.fileType = res.data.filetype
                    this.pdf_id = res.data.pdf_id
                    this.basename = res.data.basename
                    this.is_owner = res.data.is_owner
                    if(this.fileType == 2){
                        this.previewDocxFile(res.data.fileurl);
                    }else if(this.fileType == 3){
                        this.previewImages[0].src = res.data.fileurl;
                    }else if(this.fileType == 1){

                        this.url = res.data.fileurl;
                        var divs = document.querySelectorAll('div.docx-wrapper');

                        // 循环遍历div元素并删除
                        for (var i = 0; i < divs.length; i++) {
                            divs[i].parentNode.removeChild(divs[i]);
                        }
                    }
                }

            })
        },
        previewDocxFile(url) {

            const xhr = new XMLHttpRequest();
            xhr.open("get", url, true);
            xhr.responseType = "arraybuffer";
            xhr.onload = () => {
                console.log(xhr.response);
                this.wordText = xhr.response;
                let childRef = document.getElementsByClassName('childRef');
                renderAsync(this.wordText, childRef[0]).then((res) => {
                    // console.log('res---->', res);
                });
            };
            xhr.send();

        },



        // 上一页函数，
        prePage() {
            var page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页函数
        nextPage() {
            var page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        // 前往页数
        goPage() {
            if(!this.goPageNum || /\D/.test(this.goPageNum) || this.goPageNum < 1 || this.goPageNum > this.pageTotalNum) {
                this.$message.warning('输入页码有误')
                return
            }
            this.pageNum = this.goPageNum
        },
        // 页面加载回调函数，其中e为当前页数
        pageLoaded(e) {
            this.curPageNum = e
            this.arr = this.generateArray(this.pageTotalNum)
        },
        // 其他的一些回调函数。
        pdfError(error) {
            console.error(error)
        },
        is_search(pdfname,keyword){
            if(pdfname != null){
                if (pdfname.includes(keyword)) {
                    return true;
                } else {
                    return false;
                }
            }


        },
        showModal(id) {
			this.del_id = id;
            this.visible = true;
        },
        handleOk(id) {
            console.log(id)
            // return false;
            this.confirmLoading = true;
            this.$http("role.del_pdf_base",{id:id,base_id:this.id}).then(res => {
                // console.log(res);
                if(res.code == 1){
                        this.history_data = res.data;
                        this.visible = false;
                        this.confirmLoading = false;
					    this.$message.success(`文件删除成功`);
                    // console.log(this.history_data.length)
                }

            })
        },
        handleCancel(e) {
            this.visible = false;
        },
        edit_icon(index){
            this.history_data[index].is_edit = true;
            // this.is_edit = true;
        },
        sum_up(){
            this.iconLoading = true;
            this.$http("role.sum_up",{id:this.pdf_id}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.sumup_status = res.data.status;
                    this.sumup_text = res.data.sumup_text;
                    if(this.sumup_status == 1 || this.sumup_status == 2){
                        // console.log(this.sumup_status);
                        this.$notification.open({
                            message: '信息',
                            description:
                                '生成中，请稍后再试',
                            onClick: () => {
                                // console.log('Notification Clicked!');
                            },
                        });
                    }else{
                        this.sumup_open = !this.sumup_open;
                    }
                    this.iconLoading = false;
                }
            })
        },
        edit_pdf_name(index,id){
            // console.log(this.history_data[index].pdf_name)
            // console.log(id);
            this.$http("role.edit_name",{id:id,pdf_name:this.history_data[index].pdf_name}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.history_data[index].is_edit = false;
                }
            })
        },

        handleMouseEnter(index){
            console.log(index);
            this.history_data[index].show = true;

        },
        handleMouseLeave(index){
            this.history_data[index].show = false;
        },
        connectToServer() {
            //获取连接地址

            // 创建WebSocket连接
            // console.log(this.wss);
            this.socket = new WebSocket(this.wss);



            // 监听连接打开事件

            this.socket.addEventListener('open', () => {

                this.connectionStatus = 'Connected';

            });



            // 监听接收到消息事件

            this.socket.addEventListener('message', (event) => {
                // this.is_fetch = false;
                let event_obj = new Object();
                event_obj = JSON.parse(event.data);
                // console.log(event_obj);
                if(event_obj.status == 1){
                    this.num++
                };

                // console.log(this.num);
                if(this.num == 1){
                    this.getCurrentTime();
                    let temp = new Object();
                    temp.role = 'ai';
                    temp.content = '';
                    temp.time = this.currentTime;
                    temp.status = 1;
                    this.dialogue.push(temp);
                    // console.log(this.dialogue);
                }
                if(this.answer != undefined){
                    this.answer += event_obj.content;
                    this.answer =  this.answer.replace("undefined", "");
                }
                if(event_obj.status == 2){
                    this.num = 0;
                    this.dialogue[this.dialogue.length - 1].content = this.answer;
                    this.dialogue[this.dialogue.length - 1].status = 2;
                    this.answer = '';
                    // this.is_fetch = true;
                    // console.log(this.dialogue);
                    this.$http("role.save_dialogue_base", { dialogue:this.dialogue,id:this.id}).then(res => {
                    })
                };


            });



            // 监听连接关闭事件

            this.socket.addEventListener('close', () => {

                this.connectionStatus = 'Disconnected';
                this.getwss();

            });

        },
        send(){
            if(this.question == ''){
				this.$message.error("请输入问题")
                return false;
            }
			this.$http("role.zsk_kf").then(res => {
			    if(res.code == 1){
				this.getCurrentTime();
				let msg = this.eg;
				let temp = new Object();
				let msgObj = new Object();
				temp.role = 'user';
				temp.content = this.question;
				temp.time = this.currentTime;
				console.log(this.dialogue);
				if(this.dialogue == null){
					this.dialogue = new Array();
				}
				this.dialogue.push(temp);
				msgObj = JSON.parse(msg);
				msgObj.fileIds = this.fileIds;
				msgObj.messages[0].content = this.question;
				msgObj.messages[0].role = temp.role;
				let new_msg = JSON.stringify(msgObj);
				if (this.socket && this.socket.readyState === WebSocket.OPEN) {
					this.socket.send(new_msg);
				} 
				this.question = '';
			   }
			})

        },
        beforeUpload(file,fileList) {// 上传前的限制 大小 类型
            this.headers.Token = this.token;
            var temp = new Object();
            temp.id = this.id;
            this.filedata = temp;
            const limitSize = file.size / 1024 / 1024 < 20;
            if (!limitSize) {
                this.$message.error(file.name + "文件大小不能超过20M")
                return false;
            }
        },

        handleChange2(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // console.log(info.file.response);
				if(info.file.response.data){
					this.history_data = info.file.response.data.data;
					this.fileIds = info.file.response.data.fileId;
				}
                
                if(info.file.response.code == 1){
					this.getUserPdf();
					this.upload_xfyun(this.history_data[0].id,0);
                    this.$message.success(`${info.file.name} 文件上传成功`);
                }else{
                    this.$message.error(info.file.response.msg);
                }

            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        upload_xfyun(id,index){
            if(this.pdf_id  != id){
                this.loading = true;
                this.pdf_show = false;
                this.pdf_id = id;
                this.$http("role.upload_xfyun",{ id: id }).then(res => {
                    console.log(res);
                    if(res.code == 1){
                        // this.fileIds = res.data.fileIds;
                        this.txtContent = res.data.txt;
                        if(res.data.dialogue != null ){
                            const timer = setTimeout(() => {

                                this.loading = false;
                                clearTimeout(timer)

                            }, 500)

                        }else{
                            this.loading = false;
                        }
                    }else{
                        this.loading = false;
                    }
                })


                this.fileType = this.history_data[index]['file_type'];
                if(this.fileType == 2){
                    this.previewDocxFile(this.history_data[index]['url']);
                }else if(this.fileType == 3){
                    this.previewImages[0].src = this.history_data[index]['url'];
                }else if(this.fileType == 1){
                    console.log(this.history_data);
                    this.url = this.history_data[index]['url'];
                    var divs = document.querySelectorAll('div.docx-wrapper');

                    // 循环遍历div元素并删除
                    for (var i = 0; i < divs.length; i++) {
                        divs[i].parentNode.removeChild(divs[i]);
                    }
                }

                this.sumup_open = false;
            }

        },

        getUserPdf(){
            console.log(this.id);
            this.$http("role.get_base_file",{id:this.id}).then(res => {
                if(res.code == 1){
                    this.history_data = res.data;
                }

            })
        },
        getCurrentTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();

            this.currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        generateArray(n) {
            let arr = [];
            for (let i = 1; i <= n; i++) {
                arr.push(i);
            }
            return arr;
        }

},

};
</script>
<style scoped>
	.limited-text {
		  display: -webkit-box;
		  -webkit-line-clamp: 2; /* 设置显示行数为2 */
		  -webkit-box-orient: vertical;
		  overflow: hidden; /* 隐藏超出部分 */
		  text-overflow: ellipsis; /* 显示省略号 */
	}
	.ant-upload-list{
	   width:150px !important;
	}
.wrap{
    flex-grow: 1;
    /* background: url('../assets/imgs/pdfqa_bg.jpg') center no-repeat; */
	background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%),radial-gradient(circle at 85% 33%,rgba(108,99,255,.175),hsla(0,0%,100%,0) 25%);;
    background-size: 100% 100%;
    display: flex;
    height: 87vh;
    /*flex-flow: column nowrap;*/

}
.left{
    /*width: 20%;*/
    /*height: 100vh;*/
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 280px;
    flex-shrink: 0;
    height: 100%;
    background: linear-gradient(360deg,#d8ddf3,#dcebf9);
	 /* background: rgba(5,171,245,.03137254901960784); */
    border-radius: 4px;
    box-shadow: -1px 0 #fff inset;
    padding: 25px 18px 20px;
}
.left .pdf_upload_box{
    display: flex;
    align-items: center;
    justify-content: center;

}
.left .pdf_upload_btn{
	width: 150px;
}
.left .pdf_upload_btn .ant-btn{
    background: #5a81fe;
    color: #fff;
    padding: 0 30px;
    border-radius: 0;

}
.left .pdf_search{
    margin-top: 20px;
}
.left .pdf_search .ant-input{
    background: unset;
}
.left .pdf_search .ant-input::placeholder{
    color: #000;
    font-weight: normal;
}

.right{
    width: 80%;
    margin: 0 auto;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;

}
.right .right_main{
    width: 80%;

}
.right .right_main .right_main_desc{
    margin-bottom: 20px;
}
.right .right_main .right_main_desc div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
.history{
    overflow-y: auto;
    margin: 0 -15px;
    padding: 0 15px;
}
.history .history_box{
    background-color: #fff;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}
.history .history_box .pdf_name .ant-input{
    border:none;
    background-color: #eee;
    height: 30px;
}
.history .history_box .icon{

    display: flex;
}
.history .history_box .icon div{
    margin-left: 10px;
}
.history .history_box:hover{
    border: 1px solid #3e97ff;
}
.history .history_box .hb_left{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.history .history_box .hb_right{
    width: 54%;
}
.history .history_box .hb_right div:nth-child(2){
    font-size: 12px;
    font-family: PingFang SC,PingFang SC-Regular;
    color: #8890a9;
}
.hide{
    display: none !important;
}
</style>
<style scoped>

.qa_mian{
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    height: 100%;
}
.qa_mian .word{
    width: 65%;
    overflow-y: auto;
    background-color: #fff;
    margin: 0 10px;
}
#wordView{
    padding: 20px;

}
.qa_mian .txt{
    width: 65%;
    overflow-y: auto;
    background-color: #fff;
    margin: 0 10px;
    padding: 20px;
}

.qa_mian .pdf{
    width: 65%;
}
.qa_mian .pdf img{
    height: 100%;
}
.qa_mian .qa_content{
    width: 35%;
    background-color: #f8f9fb;
    padding: 3px;
    position: relative;
}
.qa_mian .qa_content .sumup{
    background: url("../assets/imgs/summary-bg.png");
    background-size: 100% 100%;
    margin: 0 15px;
    padding:15px;
    position: absolute;
}
.qa_mian .qa_content .sumup .copy{
    cursor: pointer;
}
.qa_mian .qa_content .sumup .sumup_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.qa_mian .qa_content .sumup .sumup_text{
    line-height: 23px;
    text-indent: 2em;
}

.qa_mian .qa_content .qa_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.qa_mian .qa_content .qa_top .ant-btn{
    height: 30px;
}
.qa_mian .qa_content .qa_box{
    height: 72vh;
    overflow-y: auto;
}
/* .ai{
    background-color: #f8f9fb;
} */
.qa_mian .qa_content .qa_box .dialogue_box{
    display: flex;
    padding: 20px 0;
}
.qa_mian .qa_content .qa_box .dialogue_box div:nth-child(1){
    width: 14%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.qa_mian .qa_content .qa_box .dialogue_box div:nth-child(1) img{
    width: 45%;
    border-radius: 50%;
}
.qa_mian .qa_content .qa_box .dialogue_box div:nth-child(2){
    width: 80%;
}
.qa_mian .qa_content .qa_box .dialogue_box .content_box{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    /* padding-bottom: 10px; */
}
.qa_mian .qa_content .qa_box .dialogue_box .content_box div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.qa_mian .qa_content .qa_top div:nth-child(1){
    color: #000;
    font-size: 16px;
    font-weight: bold;
}
.qa_mian .qa_content .qa_top div:nth-child(2){
    padding: 3px 12px;
    border: 1px solid #dce5fe;
    color: #2d4594;
    border-radius: 5px;
}
.qa_mian .qa_bottom .qa_bottom_nav{
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
}
.qa_mian .qa_bottom .qa_bottom_nav div{
    border: 1px solid #dce5fe;
    border-radius: 15px;
    padding: 3px 10px;
    font-size: 12px;
}
.qa_mian .qa_bottom .question{
    position: relative;
}
.qa_mian .qa_bottom .question img{
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 10px;
    cursor: pointer;
}
.qa_mian .qa_bottom .question .ant-input::placeholder{
    /*color: #000;*/
    font-weight: normal;
}
.qa_mian .qa_bottom .question .ant-input{
    height: 50px;
}
.basename{
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
}

/*滚动条*/
::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #c4c4c4;
}
</style>

<style lang="scss" scoped>
.onlineHelp {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    .tools {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 999;
        .page {
            display: inline-block;
            margin-right: 10px;
        }
    }
 .pdf-box {
 	margin-top: 20px;
     height: 750px;
     overflow: auto;
     width: 525px;
     position: relative;
 
 }
   .pdf-box span:first-child{
       position: absolute;
       top: 50%;
       transform: translateY(-50%);
   
   }
}
</style>

